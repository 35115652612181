import { AxiosError } from 'axios';
import { createEffect } from 'effector';
import { i18n } from 'next-i18next';
import config from 'config/config';
import api from '../../../services/api';
import { Data, TranslatedInfo } from '../../types';
import {
  Category,
  CategorySeo,
  CreateCategoryData,
  IPaginatedCategoryTypes,
  UpdateCategoryData,
  UpdateCategorySeo,
} from './types';

const categoriesRoute = `${config.COMMON_API_URL}/admin/v1/categories`;
const categoryTypesRoute = `${config.COMMON_API_URL}/admin/v1/category_types`;
export const categoryRoutes = {
  root: categoriesRoute,
  detail: (id: number) => `${categoriesRoute}/${id}`,
  categoryTypes: categoryTypesRoute,
  categoryTypeDetail: (id: number) => `${categoryTypesRoute}/${id}`,
};

export const fetchCategoryTypesFx = createEffect<void, IPaginatedCategoryTypes, AxiosError>(async () => {
  const response = await api.get(categoryRoutes.categoryTypes);

  return response.data;
});

export const fetchCategoriesFx = createEffect<number, Data<Category>, AxiosError>(async (id) => {
  const response = await api.get(categoryRoutes.root, {
    params: {
      language_code: i18n?.language,
      category_type_id_in: id,
      limit: 500,
    },
  });

  return response.data;
});

export const searchCategoriesFx = createEffect(async (query: any) => {
  const response = await api.get(categoryRoutes.root, {
    params: {
      search_string: query.query.search_string,
      language_code: i18n?.language,
      limit: 500,
    },
  });

  return response.data;
});

export const createCategoryFx = createEffect(async (data: CreateCategoryData) => {
  const response = await api.post(categoryRoutes.root, data);

  return response.data;
});

export const updateCategoryFx = createEffect(
  async ({ id, data }: { id: number; data: UpdateCategoryData }) => {
    const response = await api.patch(`${categoryRoutes.root}/${id}`, data);

    return response.data;
  },
);

export const createCategoryInfosFx = createEffect(
  async ({
    id,
    categoryTypeId,
    translations,
  }: {
    id: number;
    categoryTypeId: number;
    translations: TranslatedInfo[];
  }) => {
    const requests = translations.map(async (translation) => {
      const response = await api.post(`${categoryRoutes.root}/${id}/info`, translation);

      return response;
    });

    await Promise.all(requests);

    return { categoryTypeId };
  },
);

export const updateCategoryInfoFx = createEffect(
  async ({ id, translation }: { id: number; translation: TranslatedInfo }) => {
    const response = await api.patch(
      `${categoryRoutes.root}/${id}/info/${translation.language_code}`,
      translation,
    );

    return response.data;
  },
);

export const fetchCategoryFx = createEffect(async (id: string): Promise<Category> => {
  const response = await api.get(`${categoryRoutes.root}/${id}`);

  return response.data;
});

export const deleteCategoryFx = createEffect(async (id: number) => {
  const response = await api.delete(`${categoryRoutes.root}/${id}`);

  return response.data;
});

export const fetchCategorySeoFx = createEffect(async (id: number) => {
  const response = await api.get(`${categoryRoutes.root}/${id}/seo`);

  return response.data;
});

export const createCategorySeoFx = createEffect(async ({ id, seo }: { id: number; seo: CategorySeo[] }) => {
  const requests = seo.map(async (data) => {
    const response = await api.post(`${categoryRoutes.root}/${id}/seo`, data);

    return response;
  });

  await Promise.all(requests);
});

export const updateCategorySeoFx = createEffect(
  async ({ id, data }: { id: number; data: UpdateCategorySeo }) => {
    const response = await api.patch(`${categoryRoutes.root}/${id}/seo/${data.lng}`, data);

    return response.data;
  },
);
